import React from "react";
import Roadmap from "../components/Roadmap/Roadmap";
import AboutDetails from "../components/About/AboutDetails";

const Details = () => {
  return (
    <div className="bg">
      <AboutDetails />
      <Roadmap />
      {/* <Team /> */}
    </div>
  );
};

export default Details;
