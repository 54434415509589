import React from "react";

const FTMGuide = () => {
  return (
    <div className="min-h-screen bg pt-20">
      <div className="max-w-screen-xl py-10 px-5 lg:px-0 mx-auto text-lg lg:text-2xl font-light text-yellow-800">
        <h1 className="font-bold text-4xl my-4">What’s an NFT?</h1>
        <p className="mt-2">
          An NFT stands for “Non-fungible token” meaning it is a unique,
          one-of-a-kind digital item that users can buy, own, and trade on the
          blockchain. An NFT can be anything digital such as digital art,
          photography, music, and even contracts. They are bought and sold using
          cryptocurrency such as Fantom after they are minted, which will reveal
          the individual traits of your NFT (if it is part of a generative
          project like Bobbleheads). “Generative” means that the art is
          generated by an algorithm that combines predetermined traits based on
          rarity. Rarity dictates the value of the NFT, not unlike other
          collectibles such as baseball cards.
        </p>
        <h1 className="font-bold text-4xl my-4">What Does "Minting" Mean?</h1>
        <p className="mt-2">
          Minting an NFT is how your digital art becomes a part of the Fantom
          Opera blockchain–a public ledger that is unchangeable and
          tamper-proof. Similar to the way that metal coins are minted and added
          into circulation, NFTs are also tokens that get “minted” once they are
          created. Your digital artwork is represented as an NFT so it can then
          be purchased and traded in the market and digitally tracked as it is
          resold or collected again in the future.
        </p>
        <h1 className="font-bold text-4xl my-4">
          What Is Blockchain Technology?
        </h1>
        <p className="mt-2">
          Blockchain technology is most simply defined as a decentralized,
          distributed ledger that records the provenance of a digital asset. By
          inherent design, the data on a blockchain is unable to be modified
          unlike many other digital forms of ledgers. This ensures that the NFT
          art you purchase is traceable and authentic. It is being utilized by a
          number of industries including healthcare and finance due to the
          security that it offers
        </p>
        <h1 className="font-bold text-4xl my-4">Installing Metamask</h1>
        <ul className="list-inside list-disc mt-3">
          <li>Step 1. Go to the Metamask website.</li>
          <li>Step 2. Click “Get Chrome Extension” to install Metamask.</li>
          <li>Step 3. Click “Add to Chrome” in the upper right.</li>
          <li>Step 4. Click “Add Extension” to complete the installation.</li>
        </ul>
        <p className="mt-2">
          You will know Metamask has been installed when you see the fox logo on
          the upper right hand corner of your browser.
        </p>
        <h1 className="font-bold text-4xl my-4 mt-6">Using Metamask</h1>
        <p className="mt-3">
          One of the first tasks you will want to do on Metamask is to install a
          wallet (or “vault” as Metamask calls it) to hold your
          cryptocurrencies. To do this, follow the instructions below.
        </p>
        <ul className="list-inside list-disc mt-3">
          <li>
            Step 1. Click on the Metamask logo in the upper right hand corner of
            your Google chrome browser.
          </li>
          <li>
            Step 2. Read and agree to the terms and conditions. You may have to
            agree to 2 to 3 pages worth of terms.
          </li>
          <li>
            Step 3. Enter a password and click “Create” to create your wallet.
          </li>
          <li>
            Step 4. You will see a set of 12 “seed words” for your vault. Click
            “Save Seed Words as File” and copy the “MetaMask Seed Words” file
            that is downloaded to a safe place. You will need it to access your
            vault.
          </li>
          <li>
            Step 5. Click “I’ve Copied It Somewhere Safe” once your seed words
            file has been secured. You’ll be taken into your Metamask wallet!
          </li>
        </ul>
        <p className="mt-3">
          You are now in the Ethereum mainnet network.
          <a
            href="https://www.youtube.com/watch?v=OJqaZRpRqXM"
            target="_blank"
            className="text-blue-800"
            rel="noopener noreferrer"
          >
            <br />
            https://www.youtube.com/watch?v=OJqaZRpRqXM
          </a>
          - you can refer to this helpful video in case you get stuck.
        </p>
        <h1 className="font-bold text-4xl my-4 mt-6">
          How to connect MetaMask to the Fantom network (FTM)
        </h1>
        <ul className="list-inside list-disc mt-3">
          <li>
            Open MetaMask
            <br />
            Open your MetaMask wallet by clicking the MetaMask logo at the top
            right of your browser.
            <img
              src="/images/find_metamask.png"
              className="mx-auto my-5"
              alt=""
            />
          </li>
          <li className="list-item">
            Go to settings <br />
            When your MetaMask wallet is open, click on the logo of your account
            at the top right. In the dropdown menu, click on ‘Settings’.
            <img
              src="/images/go_settings.png"
              className="mx-auto w-full max-w-lg my-5"
              alt=""
            />
          </li>
          <li className="mt-3">
            Step 3. Go to networks <br />
            In the settings menu, click on ‘Networks’. In this menu you will see
            a list of networks to which your MetaMask wallet is connected. Click
            on the ‘Add Networks’ button at the bottom.
            <img
              src="/images/go_networks.png"
              //   width="500"
              className="mx-auto w-full max-w-lg my-5"
              alt=""
            />
          </li>
          <li className="mt-3">
            Step 4. Add the network details <br />
            Enter the details of the network. The following information must be
            entered: Network Name, New RPC URL, ChainID, Symbol and Block
            Explorer URL. In order to add the Fantom network, enter the
            following information:
            <ul className="list-inside ml-5 list-disc">
              <li>Network Name: Fantom Opera</li>
              <li>New RPC Url: https://rpc.ftm.tools</li>
              <li>Chain ID: 250</li>
              <li>Symbol: FTM</li>
              <li>Block Explorer URL: https://ftmscan.com/</li>
            </ul>
            <img
              src="/images/add_network.png"
              //   width="500"
              className="mx-auto w-full max-w-xs my-5"
              alt=""
            />
          </li>
          <li>
            Step 5. Connect your MetaMask to the Fantom network <br />
            Now all you need to do is connect your MetaMask wallet to the Fantom
            network. You can do so by clicking on the current network at the top
            of your MetaMask wallet, then click on Fantom Opera. <br />
            To get some FTM coin, you can buy it from one of the popular
            exchanges and then transfer it into your wallet.{" "}
            <a
              href="https://www.coingecko.com/en/coins/fantom"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.coingecko.com/en/coins/fantom
            </a>
          </li>
        </ul>

        <h1 className="text-5xl font-bold mt-6">How to mint your Bobblehead</h1>
        <ul className="list-inside list-disc mt-3">
          <li>Click the mint button</li>
          <li>
            A slider will pop up where you can choose the amount of bobbleheads
            you wish to buy (The more the better!)
          </li>
          <li>
            Once you choose amount, your Wallet will pop up where you can
            approve the transaction and pay the requisite amount of FTM tokens
            along with gas fees
          </li>
          <li>
            Voila! You're the proud owner of your very own bobblehead! Head over
            to paintswap to view
          </li>
        </ul>
        <h1 className="font-bold text-4xl my-4">How to mint your Bobblehead</h1>
        <ul className="list-inside list-disc mt-3">
          <li>Step 1. Click the mint button.</li>
          <li>
            Step 2. A slider will pop up where you can choose the amount of
            bobbleheads you wish to buy (The more the better!) .
          </li>
          <li>
            Step 3. Once you choose amount, your Wallet will pop up where you
            can approve the transaction and pay the requisite amount of FTM
            tokens along with gas fees.
          </li>
          <li>
            Step 4. Voila! You're the proud owner of your very own bobblehead!
            Head over to Paintswap to view your NFT.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FTMGuide;
