import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Navbar.css";
import { ethers } from "ethers";
import { useAlert } from "react-alert";
import twitter from "../../assets/twitter.png";

const fantomConfig = {
  chainId: `0xfa`,
  chainName: "Fantom Opera",
  nativeCurrency: {
    name: "FANTOM",
    symbol: "FTM",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.ftm.tools/"],
  blockExplorerUrls: ["https://ftmscan.com/"],
};

const Navbar = () => {
  const alert = useAlert();

  const [showNav, setShowNav] = useState(false);
  const [address, setaddress] = useState(null);

  const toggleNav = () => {
    setShowNav((prev) => !prev);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      console.log(location.hash);
      if (elem) {
        elem.scrollIntoView({ alignToTop: true, behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    setShowNav(false);
  }, [location]);

  useEffect(() => {
    if (window.ethereum) window.ethereum.on("accountsChanged", connectWallet);
  }, []);

  const connectWallet = async () => {
    console.log("CONNET WALLET");
    // window.ethereum.request
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      console.log("No metamask");
      alert.error("Please use a browser with Metamask");
      return;
    }
    //check chain id
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    console.log(chainId);
    if (chainId !== "0xfa") {
      console.log("Wrogn Chain");
      // alert.error("Please use Fantom Network ");
      try {
        await changeNetwork();
      } catch (err) {
        console.log(err);
        alert.error("Please change your network to Fantom Opera ");
        return;
      }
    }
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      // Prompt user for account connections
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      let address = await signer.getAddress();
      console.log("ADDRESS", address.slice(0, 3));
      setaddress(address);
    } catch (err) {
      console.log(err);
      if (err.message.includes("unknown account")) {
        alert.error("Please connect your Metamask Wallet!");
      } else alert.error("Sorry, An Error Occured!");
      //   setShowMintedModal(false);
    }
  };

  const changeNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [fantomConfig],
      });
    } catch (err) {
      throw err;
    }
  };

  return (
    <nav className="font-primary bg-blue-500 z-50 fixed top-0 py-4 left-0 right-0 flex text-white  lg:py-0 px-5 lg:px-8 items-center justify-between">
      <div className="nav-left-container flex w-20">
        <div className=" flex items-center px-3 ">
          <a
            href="https://twitter.com/BobbleHeadsNFT"
            target="_blank"
            className=" p-1 bg-white rounded-full "
          >
            {/* <img
              style={{
                maxWidth: "20px",
              }}
              src={twitter}
              alt="twitter"
            /> */}
            <img
              style={{
                maxWidth: "30px",
              }}
              src="https://img.icons8.com/color/48/000000/twitter--v1.png"
            />
          </a>

          <a
            href="https://discord.gg/SBmsQRZCDj"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-5 p-1 bg-white rounded-full "
          >
            <img
              style={{
                maxWidth: "30px",
              }}
              src="https://img.icons8.com/color/48/000000/discord-logo.png"
            />
          </a>
        </div>
      </div>

      <button className="block  lg:hidden" onClick={toggleNav}>
        <div
          className={`w-10 bg-white h-0.5  transition ${
            showNav ? "transform rotate-45 translate-y-2.5" : " "
          }`}
        ></div>
        <div
          className={`w-10 bg-white h-0.5 mt-2 transition ${
            showNav ? "hidden" : " "
          }`}
        ></div>
        <div
          className={`w-10 bg-white h-0.5 mt-2 transition ${
            showNav ? "transform -rotate-45" : " "
          }`}
        ></div>
      </button>

      <ul
        className={` links flex z-10 flex-col absolute left-0 ${
          showNav ? "top-full" : "-top-96"
        } w-screen lg:top-0  lg:text-xl lg:relative lg:flex-row transition-all duration-500 lg:w-auto lg:gap-5 xl:gap-10 items-center bg-blue-500`}
      >
        <li className="p-2 nav-link  ">
          <Link to="/#home">HOME</Link>
        </li>
        <li className="p-2 nav-link">
          <Link to="/#roadmap">ROADMAP</Link>
        </li>
        <li className="p-2 nav-link">
          <Link to="/#collabs">COLLABS</Link>
        </li>
        {/* <li className="p-2 nav-link">
          <Link to="/#team">TEAM</Link>
        </li>
        <li className="p-2 nav-link">
          <Link to="/ftm-guide">FTM GUIDE</Link>
        </li> */}
        {/* <li className="p-2 nav-link">
          <Link to="/details">DETAILS</Link>
        </li> */}
        <li className="p-2 nav-link">
          <Link to="/blog">BLOG</Link>
        </li>{" "}
        <li className="p-2 nav-link">
          <div className=" text-yellow-300 hover:text-yellow-500 transition-all   ">
            <Link className=" " to="/collection">
              MY COLLECTION
            </Link>
          </div>
        </li>
        <li className="p-2 nav-link">
          <div className=" text-yellow-300 hover:text-yellow-500 transition-all    ">
            <Link className=" relative " to="/rankings">
              RANKINGS
              <div className="absolute top-0 -mt-3      transform rotate-6 right-0 -mr-3 text-sm   ">
                new{" "}
              </div>
            </Link>
          </div>
        </li>
        {/* <li className="p-2 nav-link">
          <Link to="/nft-basics">NFT BASICS</Link>
        </li> */}
        <li className="p-2">
          <button
            onClick={connectWallet}
            className="my-2 text-yellow-300 bg-blue-600 hover:bg-blue-400 hover:text-yellow-400  py-2 px-3 rounded font-bold lg:my-0 transition-all"
          >
            {address ? (
              <>
                {address.slice(0, 4)}...{address.slice(-3)}
              </>
            ) : (
              "CONNECT WALLET"
            )}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
