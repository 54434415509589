import { motion } from "framer-motion";
import React from "react";
import "./Team.css";

import { teamMembers } from "../../repository/bobble_heads_data";

const Team = () => {
  const bobble = {
    animate: {
      originY: "center",
      rotate: [-8, 8, -8, 8],
      transition: {
        repeat: Infinity,
        repeatType: "mirror",
      },
    },
  };

  const height = {
    animate: {
      height: "180px",
    },
  };

  return (
    <div
      id="team"
      className="team  py-10 px-2 -dark lg:px-10 xl:px-0 text-white "
    >
      <h1 className=" text-center mb-10  text-yellow-700 text-7xl lg:text-8xl stroke">
        TEAM
      </h1>
      <div className="max-w-screen-xl  mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 place-items-center  px-5 lg:px-0 ">
        {teamMembers.map((member) => {
          return (
            <motion.div
              key={member.name}
              whileHover="animate"
              className="team-item  rounded-lg pb-28 cursor-pointer relative flex flex-col"
            >
              <div className="bobble-wrapper relative w-full">
                <motion.img
                  variants={bobble}
                  src={member.head}
                  className="absolute left-0  w-full bottom-0 right-0 z-10"
                  alt=""
                />
                <img
                  src={member.background}
                  className="absolute rounded-t-lg bottom-0 w-full left-0 right-0"
                  alt=""
                />
              </div>
              <motion.div
                variants={height}
                className="description flex flex-col items-center justify-center rounded-b-lg absolute left-0 right-0 bottom-0  bg-blue-800  px-2  py-4 w-full text-center"
              >
                <p className="text-3xl">{member.name}</p>
                <p className="text-xl">{member.title}</p>
              </motion.div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
