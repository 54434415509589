import { motion, useTransform, useViewportScroll } from "framer-motion";
import React from "react";
import { useLayoutEffect, useRef } from "react";
import Layer from "../Layer/Layer";
import "./Layers.css";

const Layers = ({
  scrollInterpolateStart,
  scrollInterpolateEnd,
  roadmapHeight,
  setLayersHeight,
  layersHeight,
}) => {
  const layersRef = useRef(null);

  const { scrollYProgress } = useViewportScroll();

  const rotateX = useTransform(
    scrollYProgress,
    [scrollInterpolateStart, scrollInterpolateEnd],
    ["0deg", "45deg"]
  );
  const rotateY = useTransform(
    scrollYProgress,
    [scrollInterpolateStart, scrollInterpolateEnd],
    ["0deg", "20deg"]
  );
  const rotateZ = useTransform(
    scrollYProgress,
    [scrollInterpolateStart, scrollInterpolateEnd],
    ["0deg", "-25deg"]
  );
  const translateX = useTransform(
    scrollYProgress,
    [scrollInterpolateStart, scrollInterpolateEnd],
    ["0px", "35px"]
  );
  const translateZ = useTransform(
    scrollYProgress,
    [scrollInterpolateStart, scrollInterpolateEnd],
    ["0px", "30px"]
  );

  useLayoutEffect(() => {
    setLayersHeight(layersRef.current.offsetHeight);
  }, [setLayersHeight]);

  const translateWrapper = useTransform(
    scrollYProgress,
    [scrollInterpolateStart, scrollInterpolateEnd],
    [0, roadmapHeight - layersHeight],
    {
      clamp: true,
    }
  );

  return (
    <motion.div
      ref={layersRef}
      style={{
        top: translateWrapper,
      }}
      className="layers mt-20 hidden lg:block"
    >
      <motion.div
        transition={{
          duration: 0,
        }}
        style={{
          perspective: 2000,
          rotateX,
          rotateY,
          rotateZ,
          translateX,
          z: translateZ,
        }}
        className="background"
      ></motion.div>
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/legs.png"
        upLift={60}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/right arm.png"
        upLift={90}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/left arm.png"
        upLift={90}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/pants.png"
        upLift={120}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/body.png"
        upLift={150}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/jacket.png"
        upLift={180}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/head.png"
        upLift={200}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/eyes.png"
        upLift={200}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/mouth.png"
        upLift={200}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/hair.png"
        upLift={240}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/hat.png"
        upLift={270}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/board.png"
        upLift={240}
      />
      <Layer
        scrollInterpolateStart={scrollInterpolateStart}
        scrollInterpolateEnd={scrollInterpolateEnd}
        scrollYProgress={scrollYProgress}
        imagePath="/images/layers/earrings.png"
        upLift={290}
      />
    </motion.div>
  );
};

export default Layers;
