import React from "react";
import "./About.css";

const AboutDetails = () => {
  return (
    <div className="about  max-w-screen-xl mx-auto shadow-text">
      <div className="what lg:flex  b0 p-5 rounded-2xl">
        <div className="left-container  text-white py-10 px-8">
          <h1 className="text-3xl lg:text-7xl font-bold text-yellow-700 stroke my-20">
            WHAT ARE BOBBLE HEADS?
          </h1>
          <p className="text-xl lg:text-3xl  mt-5 lg:mt-10 mr-4 lg:mr-24  text-yellow-700">
            Bobbleheads are 10,000 unique bobbling and adorable figures with 9
            races and over 200 hand drawn assets. Holding one gives you true
            ownership of the IP and doubles up as your membership token in this
            vibrant community.
            <br /> Launching on the Fantom Blockchain, our aim is to create an
            innovative project, packed with utility and set a precendent for NFT
            communities as well as build massively on this up and coming network
            that serves as a competitive successor to Ethereum in terms of NFTs,
            but also defi and other applications.
          </p>
        </div>
      </div>
      <div className="different lg:flex lg:mt-20">
        <div className="left-container  text-white py-10 px-8">
          <h1 className="text-3xl lg:text-7xl font-bold text-yellow-700 stroke uppercase">
            Unlimited utility and features
          </h1>
          <p className="text-xl lg:text-3xl  mt-5 lg:mt-10 mr-4 lg:mr-24 text-yellow-700">
            Owning your NFT gives you complete commercial rights and revenue
            from its derivatives, collaborations, features and partnerships Our
            partnership with @Appreciation Token ensures that we always airdrop
            multiple rewards to the HODLers from time to time We are already
            developing the companion NFTs that will greatly supplement the
            Bobbleheads and provide more utility to them
          </p>
        </div>
      </div>
      <div className="what lg:flex  b0 p-5 rounded-2xl">
        <div className="left-container  text-white py-10 px-8">
          <h1 className="text-3xl lg:text-7xl font-bold text-yellow-700 stroke uppercase">
            Transparency, Governance and a DAO
          </h1>
          <p className="text-xl lg:text-3xl  mt-5 lg:mt-10 mr-2 lg:mr-24  text-yellow-700">
            Proceeds of all primary sales and royalties will be diverted to the
            Community wallet. Members vote on where these should be used, what
            features we should invest in building and in what order, and which
            upcoming projects we should support.
            <br />
            We will launch a BOBBLE$ token that will be based on the funds from
            the community wallet ensuring a steady base price.
            <br />
            The BOBBLE$ token will be listed on an exchange, and can be traded,
            used for governance/voting, but most importantly, used to redeem
            real world utilities like private auctions, secondary NFTs, physical
            goods and merchandise, access to on-site/offline events, and much
            much more
            <br />
            Creatng a separate membership token (NFT) and utility token (erc 20)
            allows members to not only monetize their utility while holding on
            to their membership, but also choose what utility they would like to
            spend their BOBBLE$ tokens on.
            <br />
            We also have a pipeline of real world projects who want to associate
            their products with a solid brand and enter the NFT space. We're
            talking shoes, watches, beverages and other luxury products.
          </p>
        </div>
        <div className="right-container flex-shrink-0 mt-20"></div>
      </div>
    </div>
  );
};

export default AboutDetails;
