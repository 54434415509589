import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import NFTABI from '../../Blockchain/NFTABI.json';
import { useAlert } from 'react-alert';
import loading from '../../assets/loading.gif';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  getDocs,
  query,
  orderBy,
  collection,
  limit,
  startAt,
  startAfter,
  endBefore,
} from 'firebase/firestore';

export default function Rankings() {
  const [nfts, setNfts] = useState([]);
  const [page, setPage] = useState(0);
  const [isloading, setIsloading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const LIMIT = 20;

  const firebaseApp = initializeApp({
    apiKey: 'AIzaSyCFeLgbBLU7cDe-AuvsUdd4TdbKjNVEsOw',
    authDomain: 'bobbleheadsranks.firebaseapp.com',
    projectId: 'bobbleheadsranks',
    storageBucket: 'bobbleheadsranks.appspot.com',
    messagingSenderId: '885033749242',
    appId: '1:885033749242:web:80f02bd2cda5afa8b15743',
    measurementId: 'G-F7VXM5DMBE',
  });
  const db = getFirestore();

  useEffect(() => {
    getNfts();
  }, [page]);

  const getNfts = async () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setIsloading(true);
    const docRef = doc(db, 'rankings', 'Bobblehead #1');
    let q;
    try {
      if (lastVisible == null)
        q = query(
          collection(db, 'rankings'),
          orderBy('rank', 'asc'),
          limit(LIMIT)
        );
      else
        q = query(
          collection(db, 'rankings'),
          orderBy('rank', 'asc'),
          startAfter(lastVisible),
          limit(LIMIT)
        );

      const querySnapshot = await getDocs(q);
      let nftList = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data());
        nftList.push(doc.data());
      });
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setNfts(nftList);
      setIsloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getPreviousNfts = async () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setIsloading(true);
    const docRef = doc(db, 'rankings', 'Bobblehead #1');
    let q;
    try {
      if (lastVisible == null)
        q = query(
          collection(db, 'rankings'),
          orderBy('rarityScore', 'desc'),
          limit(LIMIT)
        );
      else
        q = query(
          collection(db, 'rankings'),
          orderBy('rarityScore', 'desc'),
          endBefore(lastVisible),
          limit(LIMIT)
        );

      const querySnapshot = await getDocs(q);
      let nftList = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data());
        nftList.push(doc.data());
      });
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setNfts(nftList);
      setIsloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // NFT MODAL
  const [showNFTModal, setShowNFTModal] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const NFTModal = () => {
    if (![selectedNFT]) return <></>;
    return (
      <>
        {showNFTModal ? (
          <>
            <div className='justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none my-auto'>
              <div
                className='relative w-auto my-6 mx-auto max-w-3xl '
                style={{ width: '45rem' }}>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-yellow-300 text-black outline-none focus:outline-none '>
                  {selectedNFT && selectedNFT.name ? (
                    <>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
                        <h3 className=' stroke text-3xl text-yellow-700 font-semibold'>
                          {selectedNFT.name}
                        </h3>
                        <button
                          className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                          onClick={() => setShowNFTModal(false)}>
                          <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className='relative p-6 flex-auto flex flex-row text-yellow-800  '>
                        <div className='p-5 rounded-md w-1/2 mx-5 items-center '>
                          <video
                            autoPlay={true}
                            muted
                            loop={true}
                            width='250'
                            src={selectedNFT.image}
                            className=' rounded-2xl mx-auto border-4 border-white'></video>
                          <div className='stroke font-bold text-center pt-2 text-2xl text-yellow-700'>
                            {selectedNFT.name}
                          </div>
                          <div className='font-bold text-center pt-2 text-xl text-emerald-800'>
                            Score: {selectedNFT.rarityScore.toFixed(2)}
                          </div>
                          <div className=' text-white w-1/2 mx-auto bg-blue-500 font-bold text-center rounded-lg  py-1 mt-4 text-xl text-warmGray-900'>
                            Rank : # {selectedNFT.rank}
                          </div>
                          {/* <div className='font-bold flex items-center justify-center text-center pt-2 text-xl text-warmGray-900'>
                            <a href='dadasd'>
                              <button className='btn w-full'>
                                <img
                                  src='https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg'
                                  className='w-1/4 lg:w-1/6  inline-block  mr-2 stroke-current'
                                />{' '}
                                Buy on OpenSea
                              </button>
                            </a>
                          </div> */}
                        </div>
                        <div className='  text-center text-gray-800  w-1/2  overflow-y'>
                          <div className=' py-2 text-center text-2xl font-bold uppercase text-yellow-700 stroke  '>
                            Traits
                          </div>
                          <div className=' text-white flex flex-wrap justify-start '>
                            {selectedNFT.attributes.map((trait) => (
                              <div className=' text-left  bg-yellow-600 px-2 py-1 mx-1 my-1 rounded-lg text-lg '>
                                {trait.trait_type}: {trait.value}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                        <button
                          className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                          type='button'
                          onClick={() => setShowNFTModal(false)}>
                          Close
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div className='bg min-h-screen pt-32 pb-20' >
      {NFTModal()}
      <div className='  max-w-screen-xl mx-auto shadow-text '>
        <h1 className=' text-5xl text-center  font-bold text-yellow-700 ' >
          BobbleHeads Ranking
        </h1>

        <div className=' mt-10 mx-5 md:mx-20 text-md text-yellow-700' style={{ textDecoration: 'none', textShadow: 'none' }}>
          *These rankings are not representative of final rankings, since the
          collection minting is still in progress. <br/> *The rankings are subject to
          change from time to time based on minting
        </div>

        {isloading ? (
          <>
            <div className='w-full h-96 mx-auto min-h-screen '>
              <img
                className='mx-auto mt-20 rounded-2xl'
                src={loading}
                alt='Loading...'
              />
            </div>
          </>
        ) : (
          <div className='flex flex-wrap my-10 pb-32 justify-around min-h-screen '>
            {nfts.map((nft) => (
              <div
                onClick={() => {
                  setSelectedNFT(nft);
                  setShowNFTModal(true);
                }}
                key={nft.name}
                className=' cursor-pointer mx-2  mt-5 bg-yellow-400 rounded-2xl p-4 transform transition hover:shadow-lg shadow-sm  hover:scale-105 relative '>
                <video
                  autoPlay={true}
                  muted
                  loop={true}
                  width='250'
                  src={nft.image}
                  className=' rounded-2xl mx-auto'></video>
                <p className='text-xl text-center mt-2 font-bold text-yellow-700 stroke'>
                  {nft.name}
                </p>

                <div
                  style={{ textDecoration: 'none', textShadow: 'none' }}
                  className=' bg-yellow-600 text-white px-2 py-1 absolute rounded-md left-0 top-0'>
                  Rank: {nft.rank}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='w-full  text-center'>
          <button
            onClick={getPreviousNfts}
            className=' w-32  text-center text-white bg-yellow-500    px-5 lg:px-2  py-3  rounded  text-xl mx-4'>
            Previous Page
          </button>
          <button
            onClick={getNfts}
            className=' w-32 text-center text-white bg-yellow-500    px-5 lg:px-2  py-3  rounded  text-xl mx-4'>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
}
