import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Roadmap.css";
import Percentage from "../Percentage/Percentage";
import { Link } from "react-router-dom";
import Layers from "../Layers/Layers";

const Roadmap2 = () => {
  const roadmapRef = useRef(null);

  const [scrollInterpolateStart, setScrollInterpolateStart] = useState(0);
  const [scrollInterpolateEnd, setScrollInterpolateEnd] = useState(0);
  const [roadmapHeight, setRoadmapHeight] = useState(0);
  const [layersHeight, setLayersHeight] = useState(0);

  const onLoad = useCallback(() => {
    setRoadmapHeight(roadmapRef.current.offsetHeight);
    setScrollInterpolateStart(
      (roadmapRef.current.getBoundingClientRect().top +
        document.documentElement.scrollTop +
        layersHeight) /
        document.documentElement.scrollHeight
    );

    setScrollInterpolateEnd(
      (roadmapRef.current.getBoundingClientRect().top +
        document.documentElement.scrollTop +
        roadmapRef.current.offsetHeight) /
        document.documentElement.scrollHeight
    );
  }, [layersHeight]);

  useEffect(() => {
    window.addEventListener("load", onLoad);

    return () => {
      window.removeEventListener("load", onLoad);
    };
  }, [onLoad]);

  return (
    <>
      <div
        id="roadmap"
        className="roadmap-wrapper py-20 relative bg-cover text-yellow-700 overflow-hidden"
      >
        {" "}
        <h1 className="font-bold text-6xl text-center mb-8 lg:mb-16 lg:text-8xl text-yellow-700 stroke-lg">
          ROADMAP
        </h1>
        <h1 className="text-5xl font-bold  text-center mb-8 lg:mb-16 lg:text-6xl text-yellow-700 stroke">
          PHASE 1
        </h1>
        <div ref={roadmapRef} className="flex justify-around ">
          <div className="roadmap lg:max-w-xl xl:max-w-3xl  mt-10">
            <VerticalTimeline
              layout="1-column-left"
              className="font-medium lg:text-2xl"
            >
              <VerticalTimelineElement
                icon={<Percentage percent={0} />}
                iconStyle={{ background: "#1D4ED8", color: "#fff" }}
              >
                We create the community wallet, audit our contracts, and start
                the first giveaway
              </VerticalTimelineElement>
              <VerticalTimelineElement
                iconStyle={{ background: "#1D4ED8", color: "#fff" }}
                icon={<Percentage percent={10} />}
              >
                Starting here, and at every subsequent 10%, we distribute an
                'Appreciation Token' which is an exclusive NFT for those who
                minted in this group.
              </VerticalTimelineElement>
              <VerticalTimelineElement
                iconStyle={{ background: "#1D4ED8", color: "#fff" }}
                icon={<Percentage percent={25} />}
              >
                BOBBLE$ token whitepaper release and integration of partner NFT
                projects into the GameFi ecosystem. Soon after, we will start
                distributing tokens to NFT holders.
              </VerticalTimelineElement>
              <VerticalTimelineElement
                iconStyle={{ background: "#1D4ED8", color: "#fff" }}
                icon={<Percentage percent={50} />}
              >
                Bringing our physical merchandise store to FTM featuring a wide
                range of projects. NFT holders will get early access and special
                discounts as well as free express-delivery.
              </VerticalTimelineElement>
              <VerticalTimelineElement
                iconStyle={{ background: "#1D4ED8", color: "#fff" }}
                icon={<Percentage percent={75} />}
              >
                Launching our Play-to-Earn Game where you can battle your NFTs
                and earn BOBBLE$. Integration of Chainlink VRF to ensure
                fairness and randomness for users.
              </VerticalTimelineElement>
              <VerticalTimelineElement
                iconStyle={{ background: "#1D4ED8", color: "#fff" }}
                icon={<Percentage percent={100} />}
              >
                SOLD OUT! We release the BOBBLE$ token to make it a truly
                decentral community with a DAO. This token can be spent on
                claiming all our utility.
                <br />
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
          <Layers
            layersHeight={layersHeight}
            setLayersHeight={setLayersHeight}
            roadmapHeight={roadmapHeight}
            scrollInterpolateStart={scrollInterpolateStart}
            scrollInterpolateEnd={scrollInterpolateEnd}
          />
        </div>
        <div className=" container mx-auto text-center  ">
          <Link to="/details" className="">
            <button className=" my-5   text-white bg-blue-600 hover:bg-blue-700  py-2 px-3 rounded font-bold justify-center  ">
              That's not all! Learn More
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Roadmap2;
