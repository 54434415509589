import { ethers } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import NFTABI from "../../Blockchain/NFTABI.json";
import { useAlert } from "react-alert";
import loading from "../../assets/loading.gif";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function Collection() {
  const [address, setAddress] = useState(null);
  const [isloading, setIsloading] = useState(true);
  const NFTCONTRACT = "0x8a89c505d174B056A35faF5d6c712ced921E7B48";
  // TETS
  // const NFTCONTRACT = "0x0891fe062bfb01708d0a2df7c31b64d7a9a44ed3";

  const firebaseApp = initializeApp({
    apiKey: "AIzaSyCFeLgbBLU7cDe-AuvsUdd4TdbKjNVEsOw",
    authDomain: "bobbleheadsranks.firebaseapp.com",
    projectId: "bobbleheadsranks",
    storageBucket: "bobbleheadsranks.appspot.com",
    messagingSenderId: "885033749242",
    appId: "1:885033749242:web:80f02bd2cda5afa8b15743",
    measurementId: "G-F7VXM5DMBE",
  });
  const db = getFirestore();

  const [mintedNfts, setMintedNfts] = useState([]);
  const alert = useAlert();

  const tryAddress = async () => {
    console.log("trying address");
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      // Prompt user for account connections
      //   await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      let address = await signer.getAddress();
      console.log("ADDRESS AT COLLECTION", address);
      setAddress(address);
      getNFTs();
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (window.ethereum) window.ethereum.on("accountsChanged", tryAddress);
  }, []);

  useEffect(() => {
    tryAddress();
  }, []);

  useEffect(() => {
    if (address != null) getNFTs();
  }, [address]);

  const getNFTs = async () => {
    console.log("Getting NFTs");
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );

      // console.log(await signer.getAddress());
      const signer = provider.getSigner();
      // setaddress(await signer.getAddress());

      let contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);
      // console.log(contract);
      let address = await signer.getAddress();

      //infura

      setIsloading(true);

      let tokens = await contract.tokensOfOwner(address);
      //   console.log(tokens);
      //   console.log(tokens[0].toNumber());
      let nftarray = [];
      await Promise.all(
        tokens.map(async (item) => {
          // console.log(item.toNumber());
          let uri = await contract.tokenURI(item.toNumber());
          let nft = await fetch(uri);
          // console.log(uri, await nft.json());
          let thisNFT = await nft.json();
          // console.log(thisNFT);

          nftarray = [...nftarray, thisNFT];
        })
      );
      setMintedNfts(nftarray);
      console.log(nftarray);
      setIsloading(false);
      console.log("HER");
      await getNftRanks(nftarray);
    } catch (err) {
      console.log(err);
      alert.error("Sorry, error retriving data!");

      setIsloading(false); //   setShowMintedModal(false);
    }
  };

  const getNftRanks = async (nftarray) => {
    console.log("getting ranks", nftarray);
    let newnfts = [];
    await Promise.all(
      nftarray.map(async (nft) => {
        const docRef = doc(db, "rankings", nft.name);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          let data = docSnap.data();
          nft.rarityScore = data.rarityScore;
          nft.rank = data.rank;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        newnfts.push(nft);
      })
    );

    setMintedNfts(newnfts);
  };

  // NFT MODAL
  const [showNFTModal, setShowNFTModal] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const NFTModal = () => {
    if (![selectedNFT]) return <></>;
    return (
      <>
        {showNFTModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none my-auto">
              <div
                className="relative w-auto my-6 mx-auto max-w-3xl "
                style={{ width: "45rem" }}
              >
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-yellow-300 text-black outline-none focus:outline-none ">
                  {selectedNFT && selectedNFT.name ? (
                    <>
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className=" stroke text-3xl text-yellow-700 font-semibold">
                          {selectedNFT.name}
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowNFTModal(false)}
                        >
                          <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto flex flex-row text-yellow-800  ">
                        <div className="p-5 rounded-md w-1/2 mx-5 items-center ">
                          <video
                            autoPlay={true}
                            muted
                            loop={true}
                            width="250"
                            src={selectedNFT.image}
                            className=" rounded-2xl mx-auto border-4 border-white"
                          ></video>
                          <div className="stroke font-bold text-center pt-2 text-2xl text-yellow-700">
                            {selectedNFT.name}
                          </div>
                          <div className="font-bold text-center pt-2 text-xl text-emerald-800">
                            Score: {selectedNFT.rarityScore.toFixed(2)}
                          </div>
                          <div className=" text-white w-1/2 mx-auto bg-blue-500 font-bold text-center rounded-lg  py-1 mt-4 text-xl text-warmGray-900">
                            Rank : # {selectedNFT.rank}
                          </div>
                          {/* <div className='font-bold flex items-center justify-center text-center pt-2 text-xl text-warmGray-900'>
                            <a href='dadasd'>
                              <button className='btn w-full'>
                                <img
                                  src='https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg'
                                  className='w-1/4 lg:w-1/6  inline-block  mr-2 stroke-current'
                                />{' '}
                                Buy on OpenSea
                              </button>
                            </a>
                          </div> */}
                        </div>
                        <div className="  text-center text-gray-800  w-1/2  overflow-y">
                          <div className=" py-2 text-center text-2xl font-bold uppercase text-yellow-700 stroke  ">
                            Traits
                          </div>
                          <div className=" text-white flex flex-wrap justify-start ">
                            {selectedNFT.attributes.map((trait) => (
                              <div className=" text-left  bg-yellow-600 px-2 py-1 mx-1 my-1 rounded-lg text-lg ">
                                {trait.trait_type}: {trait.value}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowNFTModal(false)}
                        >
                          Close
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div className="bg min-h-screen pt-20 pb-20">
      {NFTModal()}
      <div className="  max-w-screen-xl mx-auto shadow-text ">
        <h1 className=" text-5xl text-center  font-bold text-yellow-700 ">
          My Collection
        </h1>
        <div className="mx-auto flex flex-row flex-wrap md:px-16 z-40">
          <a
            style={{ textDecoration: "none", textShadow: "none" }}
            href="https://paintswap.finance/marketplace/collections/0x8a89c505d174b056a35faf5d6c712ced921e7b48"
            target="_blank"
            className="  bg-gray-900 text-white  px-10 lg:px-6  py-2 transform transition hover:shadow-lg  hover:scale-105 rounded mt-10 text-xl lg:text-3xl mx-auto"
          >
            View Collection on PaintSwap
          </a>

          <a
            href="https://operahouse.online/collection/0x8a89c505d174B056A35faF5d6c712ced921E7B48/`"
            style={{ textDecoration: "none", textShadow: "none" }}
            target="_blank"
            className=" bg-gray-900 text-white  px-10 lg:px-6  py-2 transform transition hover:shadow-lg  hover:scale-105 rounded mt-10 text-xl lg:text-3xl mx-auto"
          >
            View Collection on Opera House
          </a>
          <a
            href="https://nftkey.app/collections/bobbleheads/"
            style={{ textDecoration: "none", textShadow: "none" }}
            target="_blank"
            className=" bg-gray-900 text-white  px-10 lg:px-6  py-2 transform transition hover:shadow-lg  hover:scale-105 rounded mt-10 text-xl lg:text-3xl mx-auto"
          >
            View Collection on NFTKEY
          </a>
        </div>
        {address == null ? (
          <>
            <h1 className=" text-3xl text-center  font-bold text-yellow-700 stroke mt-20">
              Please connect your wallet
            </h1>
          </>
        ) : isloading ? (
          <>
            <div className="w-full h-96 mx-auto ">
              <img
                className="mx-auto mt-20 rounded-2xl"
                src={loading}
                alt="Loading..."
              />
            </div>
          </>
        ) : mintedNfts.length == 0 ? (
          <>
            <h1 className=" text-3xl text-center  font-bold text-yellow-700 stroke mt-20">
              Could not find any NFTs in your wallet!
            </h1>
          </>
        ) : (
          <div className="flex flex-wrap my-20 pb-32 justify-between">
            {mintedNfts.map((nft) => (
              <div
                onClick={() => {
                  setSelectedNFT(nft);
                  setShowNFTModal(true);
                }}
                key={nft.name}
                className=" cursor-pointer mx-2  mt-5 bg-yellow-400 rounded-2xl p-4 transform transition hover:shadow-lg shadow-sm  hover:scale-105 "
              >
                <video
                  autoPlay={true}
                  muted
                  loop={true}
                  width="250"
                  src={nft.image}
                  className=" rounded-2xl mx-auto"
                ></video>
                <p className="text-xl text-center mt-2 font-bold text-yellow-700 stroke">
                  {nft.name}
                </p>
                <div className="flex justify-center">
                  <a
                    style={{ textDecoration: "none", textShadow: "none" }}
                    // href="https://incepthink.mypinata.cloud/ipfs/QmfSH6rZaCuWZHSWvxqfn2XwF4rpRXqdFycDqy3oe716Ja/1.png"
                    href={`https://incepthink.mypinata.cloud/ipfs/QmfSH6rZaCuWZHSWvxqfn2XwF4rpRXqdFycDqy3oe716Ja/${nft.name.replace(
                      "Bobblehead #",
                      ""
                    )}.png`}
                    download={`${nft.name}.jpg`}
                    target="_blank"
                    className=" text-center w-full bg-blue-500 text-white  px-2  py-1 transform transition hover:shadow-lg  hover:scale-105 rounded mt-2 text-xl  mx-auto "
                  >
                    Get PFP Image
                  </a>

                  <div
                  style={{ textDecoration: 'none', textShadow: 'none' }}
                  className=' bg-yellow-600 text-white px-2 py-1 absolute rounded-md left-0 top-0'>
                 {nft.rank?( `Rank: ${nft.rank}` ):"Uncalculated"} 
                </div>
                  {/* <a
                    style={{ textDecoration: "none", textShadow: "none" }}
                    target="_blank"
                    href={`https://operahouse.online/collection/0x8a89c505d174B056A35faF5d6c712ced921E7B48/${nft.name.replace(
                      "Bobblehead #",
                      ""
                    )}`}
                    className=" text-center  w-full bg-gray-900 text-white  px-2  py-1 transform transition hover:shadow-lg  hover:scale-105 rounded mt-2 text-xl mx-auto "
                  >
                    View on Opera House
                  </a> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
