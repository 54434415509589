import moment from "moment";
import { useState } from "react";

const BlogPost = (props) => {
  let thepost = { title: "Loading", created_at: "...." };
  if (props.allPosts) {
    thepost = props.allPosts;
  }

  // console.log(props);

  return (
    <div>
      {/* <EventBanner
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle={thepost.title}
        Pdescription={thepost.custom_excerpt}
      /> */}
      <div className="container max-w-5xl mt-10 py-24 mx-auto">
        <div className="w-full px-4 md:px-6 text-xl text-gray-800 dark:text-gray-100 leading-normal py-16 bg-white-90 rounded-lg">
          {/* Title */}
            {/* <p className="text-base md:text-sm text-green-500 font-bold pt-9">
            &lt;{" "}
            <Link href="/#" as={"/#"}>
              <a className="text-base md:text-sm text-green-500 font-bold no-underline hover:underline">
                BACK TO BLOG
              </a>
            </Link>
          </p> */}

            {/* Top Head */}
            <div className="container flex flex-col max-w-3xl container mx-auto font-sans">
              <h1 className="font-bold font-sans break-normal text-gray-900 dark:text-gray-100 pt-6 pb-2 text-4xl md:text-5xl">
                {thepost.title}
              </h1>
              <p className="max-w-4xl pt-2 pb-4 text-lg text-gray-600 dark:text-gray-100">
                {thepost.custom_excerpt}
              </p>
              <hr className="" />
              <div className="pt-2">
                <p className="text-sm md:text-base  text-gray-600 dark:text-gray-100">
                  Published on {moment(thepost.created_at).format("Do MMM YY")}
                </p>
                <p className="text-sm md:text-base text-gray-600 dark:text-gray-100 font-sans">
                  {thepost.reading_time} MIN READ
                </p>
              </div>
            </div>
          

          {/* Main Image */}
          <div className="max-w-3xl container mx-auto font-sans">
            <img src={thepost.feature_image}></img>
          </div>

          {/*Post Content*/}

          <article className=" prose dark:prose-invert md:prose-xl max-w-none prose-ghost  sm:prose-ghost-lg dark:prose-ghost-dark ">
            <div className="max-w-3xl container mx-auto space-y-5 font-sans leading-snug"
              dangerouslySetInnerHTML={{ __html: thepost.html }}
            ></div>
          </article>
        </div>

        {/* {enableLoadComments && <p onClick={loadComments}>Load Comments</p>} */}

        {/* <div id="disqus_thread"></div> */}
        {/*/Next & Prev Links*/}
      </div>
    </div>
  );
};

export default BlogPost;
