import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Timer from "./Timer";

import NFTABI from "../../Blockchain/NFTABI.json";
import MINTPASSABI from "../../Blockchain/MINTPASSABI.json";
import { useAlert } from "react-alert";
import { ethers } from "ethers";
import loading from "../../assets/loading.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import tick from "../../assets/tick.png";

import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const Header = () => {
  const [partyTime, setPartyTime] = useState(false);

  // MINT SECTION

  const alert = useAlert();

  const [num, setNum] = useState(0);
  const [mintType, setMintType] = useState("normal");
  const [claims, setClaims] = useState(0);
  const [address, setaddress] = useState(null);
  // const PRICE = 0.1;
  const PRICE = 20;
  const MINTPASSCONTRACT = "0x25511cbb2443AaC2D9C2484553d48061a55a3CCe";
  const NFTCONTRACT = "0x8a89c505d174B056A35faF5d6c712ced921E7B48";
  // TETS
  // const MINTPASSCONTRACT = "0xf2a82768bd4f0485d4065fee1e6e3148a50772f9";
  // const NFTCONTRACT = "0x0891fe062bfb01708d0a2df7c31b64d7a9a44ed3";
  const [isloading, setIsloading] = useState(false);
  const { width, height } = useWindowSize();
  const [isconfetti, setIsconfetti] = useState(false);

  const fantomConfig = {
    chainId: `0xfa`,
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "FANTOM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/"],
    blockExplorerUrls: ["https://ftmscan.com/"],
  };

  const preChecks = async () => {
    console.log("PRE CHECKS");
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      console.log("No metamask");
      alert.error("Please use a browser with Metamask");
      return;
    }
    //check chain id
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    console.log(chainId);
    if (chainId !== "0xfa") {
      console.log("Wrogn Chain");
      // alert.error("Please use Fantom Network ");

      try {
        await changeNetwork();
      } catch (err) {
        console.log(err);
        alert.error("Please change your network to Fantom Opera ");
      }

      return false;
    } else return true;
  };

  useEffect(() => {
    if (window.ethereum) window.ethereum.on("accountsChanged", connectWallet);
  }, []);

  const connectWallet = async () => {
    console.log("CONNET WALLET");
    // window.ethereum.request
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      console.log("No metamask");
      alert.error("Please use a browser with Metamask");
      return;
    }
    //check chain id
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    console.log(chainId);
    // if (chainId !== "0xfa") {
    //   console.log("Wrogn Chain");
    //   // alert.error("Please use Fantom Network ");
    //   try {
    //     await changeNetwork();
    //   } catch (err) {
    //     console.log(err);
    //     alert.error("Please change your network to Fantom Opera ");
    //     return;
    //   }
    // }
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      // Prompt user for account connections
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      let address = await signer.getAddress();
      setaddress(address);
    } catch (err) {
      console.log(err);
      if (err.message.includes("unknown account")) {
        alert.error("Please connect your Metamask Wallet!");
      } else alert.error("Sorry, An Error Occured!");
      //   setShowMintedModal(false);
    }
  };

  const changeNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [fantomConfig],
      });
    } catch (err) {
      throw err;
    }
  };

  const findClaims = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      const signer = provider.getSigner();
      let address = await signer.getAddress();
      const contract = new ethers.Contract(NFTCONTRACT, NFTABI, provider);
      console.log(address, contract);
      const claim = await contract.balanceOfCredit(address);
      setClaims(claim.toNumber());
    } catch (err) {
      console.log("error finding claims", err);
      alert.error(
        "Sorry, Could not mint passes left in your wallet. Please try later"
      );
    }
  };

  const mint = async () => {
    let isvalid = await preChecks();

    if (!isvalid) {
      alert.error("Please use Fantom Network");
      return;
    }

    if (num == 0) {
      alert.error("Please enter a valid number to mint");
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );

      // console.log(await signer.getAddress());
      const signer = provider.getSigner();
      // setaddress(await signer.getAddress());

      let contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);
      // console.log(contract);
      let address = await signer.getAddress();
      // console.log("ADDRESS", address);
      setaddress(address);
      //infura
      setShowMintModal(false);

      setIsloading(true);
      let priceinnum = PRICE * num;
      let priceInWei = ethers.utils.parseEther(priceinnum.toString());
      // console.log(ethers.utils.parseUnits("1000000000000000", "priceInWei"));
      // console.log(wei, priceinnum.toString());
      // return;
      // let price = ethers.utils.parseUnits("1000000000000000", "wei");
      //estimate gas
      let gas, transaction;
      if (mintType == "normal") {
        console.log("normal Mint");
        gas = await contract.estimateGas.mint(num, {
          value: priceInWei,
        });
        gas = gas.mul(2);
        //transaction
        transaction = await contract.mint(num, {
          value: priceInWei,
          gasLimit: gas,
        });
      } else {
        console.log("Whitelist Mint");
        gas = await contract.estimateGas.mintWhitelist(num, {
          value: priceInWei,
        });
        gas = gas.mul(2);
        //transaction
        transaction = await contract.mintWhitelist(num, {
          value: priceInWei,
          gasLimit: gas,
        });
      }
      await transaction.wait();
      alert.success("Mint successfull!");
      setshowMintedNFtsModal(true);
      setIsloading(false);
      PlayConfetti();

      // console.log(transaction);
      let tokens = await contract.tokensOfOwner(address);
      tokens = tokens.slice(-1 * parseInt(num));
      console.log(tokens, num);
      // // console.log(tokens[0].toNumber());
      // let monstersarray = [];
      await Promise.all(
        tokens.map(async (item) => {
          console.log(item.toNumber(), "SENDIN");

          let res = await fetch(
            "https://boteshwar.medicineforchaos.tk/api/discord-notify",
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                nftId: item.toNumber(),
                channelId: "907876373215981618",
              }),
            }
          );
          console.log(res);
          //     // console.log(item.toNumber());
          //     let uri = await contract.tokenURI(item.toNumber());
          //     let nft = await fetch(uri);
          //     // console.log(uri, await nft.json());
          //     let monster = await nft.json();
          //     // console.log(monster);

          //     monstersarray = [...monstersarray, monster];
        })
      );
      // setMintedMonsters(monstersarray);
      // // console.log(monstersarray);
      // setIsloading(false);
      // ReactGA.event({
      //   category: "User",
      //   action: `Minted ${num} nft`,
      // });
    } catch (err) {
      // console.log(err.data.message);
      if (err.message.includes("unknown account")) {
        alert.error("Please connect your Metamask Wallet!");
      } else if (err.data?.message?.includes("the White List")) {
        alert.error("Sorry, You are not on the whitelist!");
      } else if (err.message.includes("Exceeds MAX_Supply")) {
        alert.error("Payment Failed,exeeded max supply!");
      } else if (err.message.includes("insufficient funds")) {
        alert.error("Payment Failed,Insufficient funds in your account!");
      } else if (err.message.includes("not on the White List")) {
        alert.error("Payment Failed,Your account is not on the Whitelist!");
      } else alert.error("Sorry, An Error Occured!");
      setIsloading(false);
    }
  };

  const claimMintPass = async () => {
    let isvalid = await preChecks();

    if (!isvalid) {
      alert.error("Please use Fantom Network");
      return;
    }
    // if (num == 0) {
    //   alert.error("Please enter a valid number to mint");
    //   return;
    // }
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );

      // console.log(await signer.getAddress());
      const signer = provider.getSigner();
      // setaddress(await signer.getAddress());

      let contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);
      // console.log(contract);
      let address = await signer.getAddress();
      // console.log("ADDRESS", address);

      //infura
      setShowMintPassModal(false);

      setIsloading(true);

      let transaction = await contract.mintCredit();
      await transaction.wait();
      alert.success("Mint Successful!");

      setshowMintedNFtsModal(true);
      setIsloading(false);
      PlayConfetti();

      // console.log(transaction);
      let tokens = await contract.tokensOfOwner(address);
      tokens = tokens.slice(-1 * parseInt(1));
      console.log(tokens, 1);
      // // console.log(tokens[0].toNumber());
      // let monstersarray = [];
      await Promise.all(
        tokens.map(async (item) => {
          console.log(item.toNumber(), "SENDIN");

          let res = await fetch(
            "https://boteshwar.medicineforchaos.tk/api/discord-notify",
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                nftId: item.toNumber(),
                channelId: "907876373215981618",
              }),
            }
          );
          console.log(res);
          //     // console.log(item.toNumber());
          //     let uri = await contract.tokenURI(item.toNumber());
          //     let nft = await fetch(uri);
          //     // console.log(uri, await nft.json());
          //     let monster = await nft.json();
          //     // console.log(monster);

          //     monstersarray = [...monstersarray, monster];
        })
      );
    } catch (err) {
      console.log(err);
      if (err.message.includes("unknown account")) {
        alert.error("Please connect your Metamask Wallet!");
      } else if (err.message.includes("Exceeds MAX_Supply")) {
        alert.error("Payment Failed,exeeded max supply!");
      } else if (err.message.includes("insufficient funds")) {
        alert.error("Payment Failed,Insufficient funds in your account!");
      } else if (err.message.includes("not on the White List")) {
        alert.error("Payment Failed,Your account is not on the Whitelist!");
      } else alert.error("Sorry, An Error Occured!");
      setIsloading(false); //   setShowMintedModal(false);
    }
  };
  const [showMintModal, setShowMintModal] = useState(false);
  const MintModal = () => {
    return (
      <>
        {showMintModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-blue-500 outline-none focus:outline-none text-white">
                  {/*header*/}
                  <div className="flex items-center justify-end   rounded-b">
                    <button
                      className="text-white background-transparent font-bold  text-lg  pt-1 px-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowMintModal(false)}
                    >
                      X
                    </button>
                  </div>
                  <div className="flex items-start justify-between px-5 md:mx-16 mx-4  rounded-t  ">
                    <h3 className="text-3xl font-semibold text-lime-400">
                      Mint Your Bobble Heads!
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative pt-6 flex-auto  justify-center flex flex-col">
                    <p className="mx-auto">
                      <button
                        onClick={() => {
                          if (num < 5) setNum(num + 1);
                          else if (mintType == "normal" && num < 20)
                            setNum(num + 1);
                        }}
                        className="text-6xl px-4 border-4 rounded-2xl transform transition  hover:scale-105"
                      >
                        {" "}
                        +
                      </button>{" "}
                      <span className="text-6xl px-4 text-center rounded-2xl ">
                        {" "}
                        {num}{" "}
                      </span>
                      <button
                        className="text-6xl px-4 border-4 rounded-2xl transform transition  hover:scale-105"
                        onClick={() => {
                          if (num > 0) {
                            setNum(num - 1);
                          }
                        }}
                      >
                        -{" "}
                      </button>
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-center p-6  rounded-b">
                    <button
                      onClick={() => {
                        mint();
                      }}
                      className=" bg-yellow-500 text-white  px-10 lg:px-16  py-2 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-3xl lg:text-5xl mx-auto"
                    >
                      Mint
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  };
  const [showMintPassModal, setShowMintPassModal] = useState(false);

  const MintPassModal = () => {
    return (
      <>
        {showMintPassModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-blue-500 outline-none focus:outline-none text-white">
                  {/*header*/}
                  <div className="flex items-center justify-end   rounded-b">
                    <button
                      className="text-white background-transparent font-bold  text-lg  pt-1 px-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowMintPassModal(false)}
                    >
                      X
                    </button>
                  </div>
                  <div className="flex items-start justify-between px-5 md:mx-16 mx-4  rounded-t  ">
                    <h3 className="text-3xl font-semibold text-lime-400 ">
                      {claims} Mint Pass Mints Left In Your Wallet
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative pt-6 flex-auto text-xl  justify-center flex flex-col text-center ">
                    <p className="">
                      {" "}
                      Each Mint Pass lets you calim 1 Bobblehead.
                    </p>
                    <p className="mb-2">
                      Click Claim NFT to claim 1 NFT using your mint pass.
                    </p>
                    {/* <p className="mx-auto">
                      <button
                        onClick={() => {
                          if (mintType == "mintpass" && num < claims)
                            setNum(num + 1);
                        }}
                        className="text-6xl px-4 border-4 rounded-2xl transform transition  hover:scale-105"
                      >
                        {" "}
                        +
                      </button>{" "}
                      <span className="text-6xl px-4 text-center rounded-2xl ">
                        {" "}
                        {num}{" "}
                      </span>
                      <button
                        className="text-6xl px-4 border-4 rounded-2xl transform transition  hover:scale-105"
                        onClick={() => {
                          if (num > 0) {
                            setNum(num - 1);
                          }
                        }}
                      >
                        -{" "}
                      </button>
                    </p> */}
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-center p-6  rounded-b">
                    <button
                      onClick={() => {
                        claimMintPass();
                      }}
                      className=" bg-yellow-500 text-white  px-10 lg:px-16  py-2 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-3xl lg:text-5xl mx-auto"
                    >
                      Claim NFT
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  };

  const [showMintedNFtsModal, setshowMintedNFtsModal] = useState(false);
  const ShowMintedNFtsModalComp = () => {
    return (
      <>
        {showMintedNFtsModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-blue-500 outline-none focus:outline-none text-white">
                  {/*header*/}
                  <div className="flex items-center justify-end   rounded-b">
                    <button
                      className="text-white background-transparent font-bold  text-lg  pt-1 px-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowMintedNFtsModal(false)}
                    >
                      X
                    </button>
                  </div>
                  <div className="flex items-start justify-between px-5 md:mx-16 mx-4  rounded-t  ">
                    <h3 className="text-3xl font-semibold text-lime-400 ">
                      NFTs Minted Successfully
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative pt-6 flex-auto text-xl  justify-center flex flex-col text-center ">
                    <img
                      className="mx-auto"
                      src={tick}
                      alt="mintedNFts"
                      width="150px"
                    />
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-center p-6  rounded-b">
                    <Link
                      to="/collection"
                      className=" bg-yellow-500 text-white  px-10 lg:px-16  py-2 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-2xl lg:text-3xl mx-auto"
                    >
                      View My Collection
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  };

  const LoadingPopup = () => {
    return (
      <div className="modal">
        <div className="overlay"></div>
        <div className="modal-content">
          <img src={loading} alt="Loading..." />
        </div>
      </div>
    );
  };

  //MINT SECTION END

  const PlayConfetti = () => {
    setIsconfetti(true);
    setTimeout(() => {
      setIsconfetti(false);
    }, 6000);
  };

  const ConfettiComponent = () => (
    <div className="z-50">
      <Confetti width={width} height={height} />
    </div>
  );

  return (
    <div
      id="home"
      className="header min-h-screen overflow-hidden flex flex-col justify-center items-center  lg:py-36 xl:py-10 lg:px-20 lg:flex-row  relative"
      style={{
        backgroundImage: `url(${require("../../assets/bg.png").default} )`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {MintModal()}
      {MintPassModal()}
      {ShowMintedNFtsModalComp()}
      {isloading ? <LoadingPopup /> : null}
      {isconfetti ? <ConfettiComponent /> : null}
      <div className="mid mx-0 my-10 flex flex-col lg:mx-20 mt-12">
        {/* <h1 className="hero-logo text-7xl text-blue-900 lg:text-9xl title ">
          Bobble
        </h1>
        <h1 className="hero-logo text-6xl lg:text-9xl text-blue-900 ml-10 title ">
          heads
        </h1> */}
        <h1 className="title-text py-2  ">
          <span>B</span>
          <span>o</span>
          <span>b</span>
          <span>b</span>
          <span>l</span>
          <span>e</span>
          <br />
          <span>H</span>
          <span>e</span>
          <span>a</span>
          <span>d</span>
          <span>s</span>
        </h1>
        {/* <h3 className="mt-7 md:mt-0 subtitle text-white  px-10    text-3xl lg:text-5xl mx-auto">
          HODL-Collect-Play
        </h3> */}
        {/* <Timer partyTime={partyTime} setPartyTime={setPartyTime} /> */}
        {/* {!partyTime ? (
          <Link
            to="/"
            className=" bg-yellow-500 text-white  px-10 lg:px-16  py-2 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-3xl lg:text-5xl mx-auto"
          >
            Coming Soon!
          </Link>
        ) : null} */}
        {/* MINT */}
        {true ? (
          <div className="mx-auto flex flex-row  md:px-20 z-40">
            {/* <button
              onClick={() => {
                console.log("modal");
                setMintType("mintpass");
                findClaims();
                setNum(0);
                setShowMintPassModal(true);
              }}
              className=" mr-10 bg-yellow-500 text-white  px-2 lg:px-8  py-2 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-xl lg:text-3xl mx-auto"
            >
              Mint Pass!
            </button>

            <button
              onClick={() => {
                console.log("modal");
                setMintType("whitelist");
                setNum(0);

                setShowMintModal(true);
              }}
              className=" bg-yellow-500 text-white  px-10 lg:px-6  py-2 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-xl lg:text-3xl mx-auto"
            >
              Whitelist Mint
            </button>
             <p className=" text-xl text-blue-700 shadow-text">
              *Mint Passes will be active when the Public Mint starts.
            </p>  */}
          </div>
        ) : null}

        {/* mint end */}

        
        {true ? (
          <button
            onClick={() => {
              console.log("modal");
              setMintType("normal");
              setNum(0);

              setShowMintModal(true);
            }}
            className=" bg-pink-500 text-white border-4 border-white hover:border-pink-600  px-16 lg:px-10  py-3 transform transition hover:shadow-lg hover:bg-white hover:text-pink-600 hover:scale-105 rounded mt-10 text-xl lg:text-3xl mx-auto"
          >
            Mint Now! 20FTM
          </button>
        ) : (
          <a
            href="https://discord.gg/Xs349ZpH"
            target="_blank"
            className=" text-center px-3 z-30  bg-blue-800 text-white  px-15 lg:px-110  py-1 transform transition hover:shadow-lg hover:bg-white hover:text-blue-600 hover:scale-105 rounded mt-6 text-xl lg:text-3xl mx-auto"
          >
            Join Our Discord For A Chance To Join the Whitelist!
          </a>
        )}
        <a
          href="https://bobbleheadsnftgame.netlify.app/"
          target="_blank"
          className=" text-center px-3 z-30  bg-blue-800 text-white  px-15 lg:px-110  py-1 transform transition hover:shadow-lg hover:bg-white hover:text-blue-600 hover:scale-105 rounded mt-6 text-xl lg:text-3xl mx-auto"
        >
          Try Our Game (Alpha verison)
        </a>
      </div>
      <div className="bobble-section flex absolute -mr-8 md:mr-0 right-0 md:right-2 lg:right-10 xl:right-48 bottom-4">
        <div className="bobble-wrapper relative">
          <motion.img
            animate={{
              originY: "center",
              rotate: [-9, 9, -9, 9],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
            src="/images/heads/000049.0 head.png"
            className="absolute left-0 bottom-0 right-0 z-10"
            alt=""
          />
          <img
            src="images/backgrounds/000049.0.png"
            className="absolute bottom-0 left-0 right-0"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
