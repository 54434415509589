import "./App.css";
// import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import FTMGuide from "./pages/FTMGuide/FTMGuide";
import Details from "./pages/Details";
import Blog from "./pages/Blog";
import Article from "./pages/Article";
import Collection from "./components/Collection/Collection";
import CTE from "./components/CTE/CTE";
import Rankings from "./pages/Ranking/Ranking";
import RankingsTemp from "./pages/Ranking/RankingTemp";
import HeaderAdmin from './components/Header/HeaderAdmin';
import Bridge from './pages/Bridge/Bridge';

function App() {
  return (
    <Router>
      <div className="App  text-secondary">
        <Navbar />
        <Routes>
          {/* <Route path="/old" exact>
            <Home />
          </Route> */}
          <Route path="/" exact>
            <Home2 />
          </Route>
          <Route path="/details" exact>
            <Details />
          </Route>
          <Route path="/ftm-guide">
            <FTMGuide />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/viewpost/:slug">
            <Article />
          </Route>{" "}
          <Route path="/collection">
            <Collection />
          </Route>
          <Route path="/cte">
            <CTE />
          </Route>
          <Route path="/rankings">
            {/* <RankingsTemp /> */}
            <Rankings />
          </Route>
          <Route path="/mintpass">
            <HeaderAdmin />
          </Route>
          <Route path="/bridge">
            <Bridge />
          </Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
