import "./partners2.css";
import partner1 from "./Partners/1.png";
import partner2 from "./Partners/2.png";
import partner3 from "./Partners/3.png";
import partner4 from "./Partners/4.png";
import partner5 from "./Partners/5.png";
import partner6 from "./Partners/6.png";
import partner7 from "./Partners/7.png";
import partner8 from "./Partners/8.png";
import partner9 from "./Partners/9.png";
import partner10 from "./Partners/10.png";
import partner11 from "./Partners/11.png";
import partner12 from "./Partners/12.png";
import partner13 from "./Partners/13.png";
import partner14 from "./Partners/14.png";
import partner15 from "./Partners/15.png";
import partner16 from "./Partners/16.png";
import partner18 from "./Partners/18.png";
import partner20 from "./Partners/20.png";
import partner21 from "./Partners/21.png";
import partner22 from "./Partners/22.png";
import partner23 from "./Partners/23.png";
import partner24 from "./Partners/24.png";
import partner25 from "./Partners/25.png";
import partner26 from "./Partners/26.png";
import partner27 from "./Partners/27.png";

const ecosystemPartners = [
  { comp: partner1, name: "Fantom" },
  { comp: partner2, name: "Chainlink" },
  { comp: partner3, name: "Potluck Labs" },
  { comp: partner4, name: "Incepthink" },
  { comp: partner5, name: "Appreciation Token" },
  { comp: partner6, name: "Opera House" },
];

const defiPartners = [{ comp: partner7, name: "Dark Matter DeFi (DMD)" }];

const commPartners = [{ comp: partner8, name: "Fantom Ecologist" }];

const nftPartners = [
  { comp: partner9, name: "Fantom Squad" },
  { comp: partner10, name: "Crypto Worms" },
  { comp: partner11, name: "World of Yamaloka" },
  { comp: partner12, name: "DMD NFT Project" },
  { comp: partner13, name: "Weaponised Countries" },
  { comp: partner14, name: "Fantom Shrooms" },
  { comp: partner15, name: "Rookie Bears" },
  { comp: partner16, name: "Pumpkitten" },
  { comp: partner18, name: "Lost FTM Warriors" },
  { comp: partner20, name: "Fantom Pocong" },
  { comp: partner21, name: "Baby Chimp Gang" },
  { comp: partner22, name: "Fantom Punks" },
  { comp: partner23, name: "Beardies" },
  { comp: partner24, name: "Raccoon Platoon" },
  { comp: partner25, name: "Raiders" },
  { comp: partner27, name: "Enri NFTs" },
];

const mediaPartners = [{ comp: partner26, name: "Fantom Tribune" }];

const Partners = () => {
  return (
    <div className="partners">
      <h1 className="  font-bold text-6xl text-center mb-8 lg:mb-16 lg:text-8xl text-yellow-700 stroke-lg">
        Partners & Collaborators
      </h1>
      <div className="colController">
        <div className="rowController">
          <div className="tag">EcoSystem</div>
          <div className="partnerImgController">
            {ecosystemPartners.map((item, index) => (
              <div key={index} className="partnerImg">
                <img src={item.comp} />
                <h1 className="partnerName">{item.name}</h1>
              </div>
            ))}
          </div>
          <div className="tag">DeFi</div>
          <div className="partnerImgController">
            {defiPartners.map((item, index) => (
              <div key={index} className="partnerImg">
                <img src={item.comp} />
                <h1 className="partnerName">{item.name}</h1>
              </div>
            ))}
          </div>
          <div className="tag">Community</div>
          <div className="partnerImgController">
            {commPartners.map((item, index) => (
              <div key={index} className="partnerImg">
                <img src={item.comp} />
                <h1 className="partnerName">{item.name}</h1>
              </div>
            ))}
          </div>
        </div>
        <div className="rowController">
          <div className="tag">NFT</div>
          <div className="partnerImgController">
            {nftPartners.map((item, index) => (
              <div key={index} className="partnerImg">
                <img src={item.comp} />
                <h1 className="partnerName">{item.name}</h1>
              </div>
            ))}
          </div>
          <div className="tag">Media</div>
          <div className="partnerImgController">
            {mediaPartners.map((item, index) => (
              <div key={index} className="partnerImg">
                <img src={item.comp} />
                <h1 className="partnerName">{item.name}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
