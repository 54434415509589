import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative  bg-blue-900 pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-center lg:text-left justify-between">
          {/* <img
            // src="images/LittleCutieCorgis_WebsiteTop.png"
            width="100"
            className="hidden lg:block"
            alt="Bobble Heads"
          /> */}
          <div className="w-full lg:w-6/12 px-4  text-2xl text-white">
            <div className="flex flex-col md:flex-row md:flex-wrap items-top mb-6">
              <Link className="mt-5 mx-10" to="/#home">
                Home
              </Link>
              <Link className="mt-5 mx-10" to="/#about">
                About
              </Link>

              <Link className="mt-5 mx-10" to="/#roadmap">
                Roadmap
              </Link>
              <Link className="mt-5 mx-10" to="/#team">
                Team
              </Link>
              {/* <Link className="mt-5 mx-10" to="/tnc">
                Terms&Conditions
              </Link> */}
            </div>
          </div>
        </div>
        <div className="mt-6 lg:mb-0 mb-6 flex justify-end  ">
          {/* <FontAwesomeIcon icon="github" color="white" /> */}
          {/* 
          <div className="social flex items-center ">
            <a
              href="https://discord.gg/hgXdVgYH2R"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-primary p-2 rounded-full"
            >
              <img src="/images/discord-2-32.png" width="32" alt="" />
            </a>
            <a
              href="https://twitter.com/corgicutiesnft"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-primary p-2 rounded-full ml-5"
            >
              <img src="/images/twitter-32.png" width="32" alt="" />
            </a>
           
          </div> */}
        </div>
        <hr className="my-6 border-secondary" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center text-white">
            <div className="text-sm  font-semibold py-1">
              © 2021 Bobble Heads, Inc. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
