import React from "react";
import "./About.css";
import { Link } from "react-router-dom";

const About2 = ({ price }) => {
  // console.log("Price", price);
  return (
    <div className="about  max-w-screen-xl mx-auto shadow-text">
      <div className="what lg:flex  b0 p-5 rounded-2xl">
        <div className="left-container  text-white py-10 px-8">
          <h1 className="text-3xl lg:text-7xl font-bold text-yellow-700 stroke">
            WHAT ARE BOBBLE HEADS?
          </h1>
          <p className="text-xl lg:text-3xl  mt-5 lg:mt-10 mr-4 lg:mr-24  text-yellow-700 ">
            Bobbleheads are 10,000 unique bobbling and adorable figures with 9
            crypto themed races (bulls, bears, shibas, pepes, etc) and over 200
            hand drawn assets. Holding one gives you true ownership of the IP
            and doubles up as your membership token in this vibrant community.
            <br /> Bobbleheads will be launched on the Fantom Opera blockchain
            and have a mint price of 20 FTM.
          </p>
          <br />
          <div className="bg-white shadow-md rounded-lg text-3xl text-blue-500 font-semibold inline px-5 py-2 mt-20 ">
            20 FTM = {(20 * price).toFixed(2)} USD{" "}
            <span className=" text-base">(Powered by Chainlink)</span>
          </div>
        </div>
        <div className="hidden lg:block right-container flex-shrink-0 mt-20 ">
          <video
            autoPlay={true}
            muted
            loop={true}
            width="450"
            src="https://incepthinkgeneraluploads.s3.us-east-2.amazonaws.com/bobbleheads/bobble4.mp4"
            className=" rounded-3xl"
          ></video>
        </div>
      </div>
      <div className="different lg:flex lg:mt-20">
        <div className="right-container flex-shrink-0 lg:mt-20 mx-30 lg:mx-20 ">
          <video
            autoPlay={true}
            muted
            loop={true}
            width="450"
            src="https://incepthinkgeneraluploads.s3.us-east-2.amazonaws.com/bobbleheads/bobble2.mp4"
            className=" rounded-3xl"
          ></video>
        </div>
        <div className="left-container  text-white py-10 px-8">
          <h1 className="text-3xl lg:text-7xl font-bold text-yellow-700 stroke uppercase">
            GameFi and utility!
          </h1>
          <p className="text-xl lg:text-3xl  mt-5 lg:mt-10 mr-4 lg:mr-24 text-yellow-700">
            We are building a cross-NFT project game where people can form teams
            and battle their NFTs from various supported projects to win tokens.
            We will also launch a physical merchandise store with not only ours,
            but other NFT products as well.
          </p>
        </div>
      </div>
      <div className="what lg:flex  b0 rounded-2xl">
        <div className="left-container  text-white py-10 px-8">
          <h1 className="text-3xl lg:text-7xl font-bold text-yellow-700 stroke uppercase">
            Transparency, Governance and a DAO
          </h1>
          <p className="text-xl lg:text-3xl  mt-5 lg:mt-10 mr-2 lg:mr-24  text-yellow-700">
            We will launch a BOBBLE$ token which will be continuously airdropped
            to all the holders allowing them to monetize their utility and
            decide which and what all utility they would like to claim. This
            token will also allow them to vote in the Bobble DAO that will act
            as a launchpad to support upcoming projects in the Fantom ecosystem
          </p>
          <Link to="/details">
            <button className="my-5 text-white bg-blue-600 hover:bg-blue-700  py-2 px-3 rounded font-bold ">
              That's not all! Learn More
            </button>
          </Link>
        </div>
        <div className="right-container hidden lg:block flex-shrink-0 mt-20">
          <video
            autoPlay={true}
            muted
            loop={true}
            width="450"
            src="https://incepthinkgeneraluploads.s3.us-east-2.amazonaws.com/bobbleheads/bobble1.mp4"
            className=" rounded-3xl"
          ></video>
        </div>
      </div>
    </div>
  );
};

export default About2;
