import GhostContentAPI from "@tryghost/content-api";

//  const { BLOG_URL, CONTENT_API_KEY } = process.env;

const BLOG_URL = "https://blog.incepthink.com";
const CONTENT_API_KEY = "134f7d8394ff6aef6b8f2645e6";


const ghostapi = new GhostContentAPI({
  url: BLOG_URL,
  key: CONTENT_API_KEY,
  version: "v3",
});

export default ghostapi;
