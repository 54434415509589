import React, { Component } from "react";
import loading from "../../assets/loading.gif";

import axios from "axios";
class Contacts extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = async (e) => {
    e.preventDefault();
    const { name, email, subject, message } = this.state;

    if (name == "" || email == "") {
      alert("Please enter valid name and email.");
      return;
    }
    console.log("HERE");
    this.setState({ ...this.state, loading: true });

    console.log(name, email, subject, message);

    let data = {
      subject: "BOBBLEHEADS CTE",
      clientEmail: email,
      message: `RESPONSE FROM CTE FORM BY : ${name}, ${email}`,
      targetMail: "contact@incepthink.com",
    };

    try {
      const res = await axios.post(
        "https://jx5nee07ri.execute-api.us-east-2.amazonaws.com/dev/",
        data
      );
      //   console.log(res);
      console.log(res);
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
        loading: false,
      });
      alert("Thanks for joining the cause!");
    } catch (error) {
      console.log(error);
      alert("Sorry, an error occured!");
      this.setState({ ...this.state, loading: false });
    }
  };

  render() {
    // const {name, email, subject, message, emailStatus} = this.state;
    return (
      <div className="  ">
        {this.state.loading ? (
          <div className="modal">
            <div className="overlay"></div>
            <div className="modal-content">
              <img src={loading} alt="Loading..." />
            </div>
          </div>
        ) : null}
        <form className="w-full max-w-lg mx-auto" onSubmit={this.submitForm}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className=" text-xl block uppercase tracking-wide text-gray-700  font-bold mb-2"
                for="grid-first-name"
              >
                Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Name"
                onChange={this.handleChange}
                name="name"
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className=" text-xl block uppercase tracking-wide text-gray-700  font-bold mb-2"
                for="grid-first-name"
              >
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Email"
                name="email"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className=" text-xl block uppercase tracking-wide text-gray-700  font-bold mb-2"
                for="grid-password"
              >
                I consent for the use of my likeness for NFTs to help research
                and abate CTE
              </label>
              <input type="checkbox" defaultChecked="true" />
            </div>
          </div>

          <div className="md:flex md:items-center pb-60">
            <div className="md:w-1/3">
              <button
                className="shadow bg-blue-600 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Join The Cause
              </button>
            </div>
            <div className="md:w-2/3"></div>
          </div>
        </form>
      </div>
    );
  }
}

export default Contacts;
