import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel/Carousel";
import Header from "../components/Header/Header";
import Roadmap2 from "../components/Roadmap/Roadmap2";
import Team from "../components/Team/Team";
import About2 from "../components/About/About2";
import Partners2 from "../components/Partners/Partners2";
import { ethers } from "ethers";
import Mint from "../components/MInt/Mint";
import ReactGA from "react-ga";
ReactGA.initialize("UA-208588891-5");

const Home2 = () => {
  const [price, setPrice] = useState(0);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const chailink = async () => {
    let ftm;
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://rpc.ftm.tools/"
      );
      const aggregatorV3InterfaceABI = [
        {
          inputs: [],
          name: "decimals",
          outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "description",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint80", name: "_roundId", type: "uint80" },
          ],
          name: "getRoundData",
          outputs: [
            { internalType: "uint80", name: "roundId", type: "uint80" },
            { internalType: "int256", name: "answer", type: "int256" },
            { internalType: "uint256", name: "startedAt", type: "uint256" },
            { internalType: "uint256", name: "updatedAt", type: "uint256" },
            { internalType: "uint80", name: "answeredInRound", type: "uint80" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "latestRoundData",
          outputs: [
            { internalType: "uint80", name: "roundId", type: "uint80" },
            { internalType: "int256", name: "answer", type: "int256" },
            { internalType: "uint256", name: "startedAt", type: "uint256" },
            { internalType: "uint256", name: "updatedAt", type: "uint256" },
            { internalType: "uint80", name: "answeredInRound", type: "uint80" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "version",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
      ];
      const addr = "0xf4766552D15AE4d256Ad41B6cf2933482B0680dc";
      const priceFeed = new ethers.Contract(
        addr,
        aggregatorV3InterfaceABI,
        provider
      );
      let roundData = await priceFeed.latestRoundData();
      // Do something with roundData
      console.log(
        "FANTOM / ETH",
        roundData,
        (roundData.answer.toNumber() / 10e7).toFixed(2)
      );
      ftm = (await roundData.answer.toNumber()) / 10e7;
      setPrice(ftm.toFixed(2));
    } catch (err) {
      console.log("Chainlink Error");
    }
  };

  useEffect(() => {
    chailink();
  }, []);
  return (
    <div className="bg">
      <Header />
      <Carousel />
      {/* <Mint /> */}
      <About2 price={price} />
      <Roadmap2 />
      <Partners2 />
      <Team />
    </div>
  );
};

export default Home2;
