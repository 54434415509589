export const carouselBobbleHeads = [
  {
    head: "/images/heads/000003.0 head.png",
    background: "/images/backgrounds/000003.0.png",
  },
  {
    head: "/images/heads/000031.0 head.png",
    background: "/images/backgrounds/000031.0.png",
  },
  {
    head: "/images/heads/000032.0 head.png",
    background: "/images/backgrounds/000032.0.png",
  },
  {
    head: "/images/heads/000037.0 head.png",
    background: "/images/backgrounds/000037.0.png",
  },
  {
    head: "/images/heads/000039.0 head.png",
    background: "/images/backgrounds/000039.0.png",
  },
  {
    head: "/images/heads/000040.0 head.png",
    background: "/images/backgrounds/000040.0.png",
  },
  {
    head: "/images/heads/000042.0 head.png",
    background: "/images/backgrounds/000042.0.png",
  },
  {
    head: "/images/heads/000045.0 head.png",
    background: "/images/backgrounds/000045.0.png",
  },
  {
    head: "/images/heads/000046.0 head.png",
    background: "/images/backgrounds/000046.0.png",
  },
  {
    head: "/images/heads/000048.0 head.png",
    background: "/images/backgrounds/000048.0.png",
  },
];

export const teamMembers = [
  {
    head: "/images/heads/000052.0 head.png",
    background: "/images/backgrounds/000052.0.png",
    name: "CryptoDude",
    title: "Project Lead",
  },
  {
    head: "/images/heads/000058.0 head.png",
    background: "/images/backgrounds/000058.0.png",
    name: "Tritonn",
    title: "Artist Extraordinnaire",
  },
  {
    head: "/images/heads/139.png",
    background: "/images/backgrounds/000139.0.png",
    name: "InfamousDegen",
    title: "Community Manager",
  },
  {
    head: "/images/heads/000064.0 head.png",
    background: "/images/backgrounds/000064.0.png",
    name: "Evannator",
    title: "Illustrator and Digital Assets genius, Community Manager",
  },
  {
    head: "/images/heads/000067.0 head.png",
    background: "/images/backgrounds/000067.0.png",
    name: "ParryHotter",
    title: "Project Manager, Social Media Partner",
  },
  {
    head: "/images/heads/000098.0 head.png",
    background: "/images/backgrounds/000098.0.png",
    name: "Eclipse",
    title: "Techie",
  },
];
