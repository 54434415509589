import { motion } from "framer-motion";
import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Carousel.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { carouselBobbleHeads } from "../../repository/bobble_heads_data";

const Carousel = () => {
  SwiperCore.use([Autoplay]);

  return (
    <div className="carousel pb-10 pt-1">
      <Swiper
        spaceBetween={25}
        loop={true}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },

          1024: {
            slidesPerView: 3,
          },

          1600: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={1}
        speed={2000}
        autoplay={{ delay: 500, disableOnInteraction: false }}
        freeMode={true}
      >
        {carouselBobbleHeads.map((bh) => {
          return (
            <SwiperSlide key={bh.head + bh.background}>
              <div className="carousel-item  bg-gradient-to-br flex justify-center items-center bg-black">
                <div className="bobble-wrapper-carousel  relative">
                  <motion.img
                    animate={{
                      rotate: [-12, 12, -12],
                    }}
                    transition={{
                      duration: 1,
                      type: "spring",
                      repeat: Infinity,
                      repeatType: "mirror",
                    }}
                    src={bh.head}
                    className="absolute left-0 right-0 bottom-0 z-10"
                    alt=""
                  />
                  <img
                    src={bh.background}
                    className="absolute w-full rounded-lg bottom-0 left-0 right-0"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div id="mint"></div>
    </div>
  );
};

export default Carousel;
