import React, { useState, useEffect } from "react";
import Roadmap from "../components/Roadmap/Roadmap";
import AboutDetails from "../components/About/AboutDetails";
import BlogList from "../components/Blog/BlogList";
import ghostapi from "../connectGhost";
import BlogPost from "../components/Blog/BlogPost";
import { useParams } from "react-router-dom";

const Article = () => {
  const [thepost, setThePost] = useState();

  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await ghostapi.posts
          .read({ slug: slug })
          .then((post) => {
            console.log("received");

            setThePost(post);
            // console.log(post);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.log(error);
      }
      // console.log(props);
    };
    fetchPosts();
    console.log(slug);
  }, []);

  return (
    <div className="bg min-h-screen">
      {/* <AboutDetails /> */}
      <div className="mx-5 mx-md-24">
        <BlogPost allPosts={thepost} />
      </div>

      {/* <Team /> */}
    </div>
  );
};

export default Article;
