import React from "react";




const Bridge = () => {
  return (
    <div className="bg">
    <div className="about  max-w-screen-xl mx-auto h-screen">
      
    <iframe src="https://bridge.umbria.network/bridge/ethereum-fantom/eth"></iframe>
      
    </div>
    </div>
  );
};

export default Bridge;
