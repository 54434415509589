import React from "react";
import Contacts from "./ContactForm";

const CTE = () => {
  return (
    <div className="min-h-screen bg pt-20">
      <h1 className="text-3xl lg:text-5xl font-bold text-yellow-700 stroke uppercase text-center mt-10">
        Bobbleheads CTE Initiative
      </h1>
      <div className="max-w-screen-xl py-10 px-5 lg:px-0 mx-auto text-lg lg:text-2xl font-light text-yellow-800">
        <h1 className="font-bold text-4xl my-4">What is CTE?</h1>
        <p className="mt-2">
          Chronic Traumatic Encephalopathy (CTE) is a degenerative brain disease
          associated with traumatic brain injuries, and is a result of repeated
          blows to the head, frequent concussions. The encephalopathy symptoms
          can include behavioral problems, mood problems, and problems with
          thinking. The disease often gets worse over time and can result in
          dementia. It affects sports, military veterans, and others who have
          experienced repeated brain trauma, according to the Boston University
          CTE Center. A protein called tau misfolds and malfunctions in CTE,
          causing other proteins to misfold as well. This sets off a chain
          reaction in which the defective tau spreads throughout the brain,
          killing brain cells. CTE has been detected in persons as young as 17,
          but symptoms usually don't develop until years after a head injury has
          occurred.
        </p>
        <h1 className="font-bold text-4xl my-4">
          Why should we care about CTE?
        </h1>
        <p className="mt-2">
          Ex-athletes are often the centre of conversation when it comes to CTE.
          Due to the nature of the sport, American football players and boxers
          are vulnerable to CTE. The cause is lesser-known and needs support to
          further its research and setting up of organisations to help victims.
        </p>
        <h1 className="font-bold text-4xl my-4">Football and CTE</h1>
        <p className="mt-2">
          In July 2017, a research published in the Journal of the American
          Medical Association (JAMA) discovered that 177 of 202 deceased former
          football players had been diagnosed with CTE. The study included 111
          former NFL players, 110 of whom were diagnosed with CTE.
          <br />
          Aaron Hernandez, a former NFL great, is a well-known example of CTE.
          Hernandez killed himself in prison, where he was serving a life
          sentence for murder. He had a severe case of CTE, according to the
          postmortem data, the worst instance ever reported on such a young man
          (Hernandez was only 27 years old). Doctors divide CTE into four
          stages, with Stage 4 being the most severe. Hernandez was diagnosed
          with Stage 3 CTE, which results in memory loss, damaged thinking,
          behavioural abnormalities, and impaired judgement.
        </p>
        <h1 className="font-bold text-4xl my-4">
          Bobbleheads, Football, CTE charity
        </h1>
        <p className="mt-2">
          The 'Bobbleheads' are a common association with American Football
          being often given out at live games. While it's preferred their heads
          get knocked around, we hope our beloved football stars don't face the
          same and have therefore decided to dedicate all profits from the
          upcoming Bobbleheads NFT launch on ethereum, towards The Concussion
          Foundation that researches on the mitigation and early diagnosis of
          CTE. We hope the funds raised help accelerate and bring awareness to
          their cause. If you would be kind enough to allow us to use your
          likeness for one of our NFTs, it would greatly help our goal to
          achieve more progress in researching this field.
        </p>
      </div>
      {/* <Contacts /> */}
      <div className=" flex justify-center  ">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScMJNufVEpSgakLAzCqLasPwt5ruvckmXYpocEDhaF6N-Xujg/viewform?embedded=true"
          width="640"
          height="929"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default CTE;
