import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Roadmap.css";
import ball from "../../assets/ball.png";
import Percentage from "../Percentage/Percentage";
import "../About/About.css";
const Roadmap = () => {
  return (
    <>
      <div
        id="roadmap"
        className="roadmap-wrapper py-20  bg-cover text-yellow-700 "
      >
        {" "}
        <h1 className="  font-bold text-6xl text-center mb-8 lg:mb-16 lg:text-8xl text-yellow-700 stroke-lg">
          ROADMAP
        </h1>
        <h1 className=" text-5xl font-bold  text-center mb-8 lg:mb-16 lg:text-6xl text-yellow-700 stroke">
          PHASE 1
        </h1>
        <div className="roadmap max-w-screen-xl mx-auto mt-10">
          <VerticalTimeline className="font-medium  lg:text-2xl">
            <VerticalTimelineElement
              icon={<Percentage percent={0} />}
              iconStyle={{ background: "#1D4ED8", color: "#fff" }}
            >
              We create the community wallet, audit our contracts, and start the
              first giveaway
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "#1D4ED8", color: "#fff" }}
              icon={<Percentage percent={10} />}
            >
              Starting here, and at every subsequent 10%, we distribute an
              'Appreciation Token' to everyone in that range, this will be an
              immersive NFT with an original soundtrack and utility to unlock
              access in an upcoming puzzle
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "#1D4ED8", color: "#fff" }}
              icon={<Percentage percent={25} />}
            >
              BOBBLE$ token whitepaper release and integration of partner NFT
              projects into the GameFi ecosystem. Soon after, we will start
              distributing tokens to NFT holders.
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "#1D4ED8", color: "#fff" }}
              icon={<Percentage percent={50} />}
            >
              Bringing our physical merchandise store to FTM featuring a wide
              range of projects. NFT holders will get early access and special
              discounts as well as free express-delivery.
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "#1D4ED8", color: "#fff" }}
              icon={<Percentage percent={75} />}
            >
              Launching our Play-to-Earn Game where you can battle your NFTs and
              earn BOBBLE$. Integration of Chainlink VRF to ensure fairness and
              randomness for users.
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "#1D4ED8", color: "#fff" }}
              icon={<Percentage percent={100} />}
            >
              SOLD OUT! We release the BOBBLE$ token to make it a truly
              decentral community with a DAO. This token can be spent on
              claiming all our utility.
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
        <h1 className="  font-bold text-5xl text-center mb-8 lg:mb-16 lg:text-6xl text-yellow-700 stroke mt-20">
          PHASE 2
        </h1>
        <div className="container  mx-auto shadow-text">
          <div className="flex my-10  mx-1 lg:mx-36">
            <img
              alt=""
              src={ball}
              width="40px"
              className=" w-14 h-14 m-2 lg:m-5 "
            />{" "}
            <div className="text-xl lg:text-3xl   mt-6 pr-4  text-yellow-700">
              We launch a companion NFT that will be free to mint for all the
              Bobbleheads holders
            </div>
          </div>
          <div className="flex my-10 mx-1 lg:mx-36">
            <img
              alt=""
              src={ball}
              width="40px"
              className=" w-14 h-14 m-2 lg:m-5 "
            />{" "}
            <div className="text-xl lg:text-3xl   mt-6 pr-4  text-yellow-700">
              We build a rarity website where people can check the rank and
              trait-wise rarity of their NFTs for all projects
            </div>
          </div>

          <div className="flex my-10 mx-1 lg:mx-36">
            <img
              alt=""
              src={ball}
              width="40px"
              className=" w-14 h-14 m-2 lg:m-5 "
            />{" "}
            <div className="text-xl lg:text-3xl   mt-6 pr-4  text-yellow-700">
              We create software for people to receive high resolution files of
              their NFTs in various formats so they can use their bobbleheads as
              phone backgrounds, laptop backgrounds, profile pictures, etc.
            </div>
          </div>
          <div className="flex my-10 mx-1 lg:mx-36">
            <img
              alt=""
              src={ball}
              width="40px"
              className=" w-14 h-14 m-2 lg:m-5 "
            />{" "}
            <div className="text-xl lg:text-3xl   mt-6 pr-4  text-yellow-700">
              We create a launchpad that incubates and supports up and coming
              projects on the Fantom ecosystem. The current community is very
              small and concise, having access to the right technology and
              mentorship can help accelerate the growth of projects in the
              network. Creating a platform to enable this is our pleasure.
            </div>
          </div>
          <div className="flex my-10 mx-1 lg:mx-36">
            <img
              alt=""
              src={ball}
              width="40px"
              className=" w-14 h-14 m-2 lg:m-5 "
            />{" "}
            <div className="text-xl lg:text-3xl   mt-2 pr-4  text-yellow-700">
              We plan a Convention where the Bobbleheads can meet and truly be
              in their own element. We are discussing partnerships with
              Comic-Con, Art Basel, Global Crypto Summit, and Filecoin
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
