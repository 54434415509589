import React, { useState, useEffect } from "react";
import Roadmap from "../components/Roadmap/Roadmap";
import AboutDetails from "../components/About/AboutDetails";
import BlogList from "../components/Blog/BlogList";
import ghostapi from "../connectGhost";

const Blog = () => {
  const [props, setProps] = useState();

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await ghostapi.posts
        .browse({
          limit: "all",
          fields: [
            "title",
            "slug",
            "custom_excerpt",
            "published_at",
            "feature_image",
            "reading_time",
          ],
          filter: "tag:bobbleheads",
        })
        .catch((err) => {
          console.error(err);
        });
      setProps(posts);
      // console.log(posts);
    };
    fetchPosts();
  }, []);

  return (
    <div className="bg min-h-screen ">
      {/* <AboutDetails /> */}
      <div className="mx-5 mx-md-32">
        <BlogList allPosts={props} />
      </div>

      {/* <Team /> */}
    </div>
  );
};

export default Blog;
